<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useToast } from 'vue-toastification'
import { useQueryClient } from '@tanstack/vue-query'

import type { IBhs } from '@/modules/settings/interfaces/BusinessHours'
import ButtonG from '@/modules/shared/components/ButtonComponent.vue'
import useModal from '@/modules/shared/composable/useModal'
import businessHoursC from '@/modules/settings/components/businessHours'
import useCompanyBusinessHours from '@/modules/settings/composables/useCompanyBusinessHours'
import ToastComponent from '@/modules/shared/components/ToastComponent.vue'
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

const { hideModal } = useModal()
const { businessHours, createCompanyBusinessHours, deleteCompanyBusinessHours } = useCompanyBusinessHours()
const toast = useToast()
const queryClient = useQueryClient()
const { userInfo } = useUserInfo()

const readOnly = userInfo.value.features?.business_hours?.read_only

const editBusinessData = ref<IBhs>({
  timezone: businessHours.value.timezone,
  businessHours: businessHours.value.businessHours
})

const inactive = computed(() => ['', 'UTC'].includes(editBusinessData.value.timezone))

watch(businessHours, (newBusinessHours) => {
  editBusinessData.value.timezone = newBusinessHours.timezone
  if (newBusinessHours.businessHours) editBusinessData.value.businessHours = newBusinessHours.businessHours
})

const save = () => {
  if (!inactive.value) {
    if (editBusinessData.value.businessHours.length) {
      const newBusinessHours = {
        business_hours: editBusinessData.value.businessHours.map((bsh) => {
          return { weekday: bsh.id.slice(0, 3), start_time: `${bsh.start_time}:00`, end_time: `${bsh.end_time}:00` }
        }),
        timezone: editBusinessData.value.timezone
      }

      createCompanyBusinessHours.mutate(newBusinessHours, {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['companyBusinessHours'], { exact: true })
          query?.fetch()
          hideModal()
          toast.success({
            component: ToastComponent,
            props: {
              title: 'Your business hours have been updated',
              messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
            },
            options: { icon: CheckCircleIcon }
          }, {
            timeout: 10000
          })
        }
      })
    } else {
      deleteCompanyBusinessHours.mutate('', {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['companyBusinessHours'], { exact: true })
          query?.fetch()
          hideModal()
          toast.success({
            component: ToastComponent,
            props: {
              title: 'Your business hours have been updated',
              messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
            },
            options: { icon: CheckCircleIcon }
          }, {
            timeout: 10000
          })
        }
      })
    }
  }
}
</script>

<template>
  <div class="mt-6 flex gap-8">
    <div class="basis-2/5 min-[1580px]:basis-2/6">
      <p class="text-sm leading-5 font-medium text-gray-900">Company Business Hours</p>
      <p class="text-sm leading-5 font-normal text-gray-500 mt-2">Define the hours that your team is expected to be available to respond to emails. Hours that fall outside this schedule will not count towards response times.</p>
    </div>
    <div class="basis-3/5 min-[1580px]:basis-4/6 bg-white rounded-md shadow-sm">

      <div class="p-6">
        <businessHoursC v-model:days="editBusinessData.businessHours" v-model:timezone="editBusinessData.timezone" :data="editBusinessData" :read-only="readOnly" />
      </div>

      <div v-if="!readOnly" class="py-3 px-6 flex justify-end border-t border-gray-200">
        <ButtonG text="Save" class="!w-[67px]" :inactive="inactive" @Click="save" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
