/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useMutation, useQuery } from '@tanstack/vue-query'
import clientsApi from '@/api/clients-api'

import type { IIBhs } from '@/modules/settings/interfaces/BusinessHours'
import { BUSINESSHOURS } from '@/api/url'
import { useMailboxesStore } from '@/modules/settings/stores/mailboxes'
import { weekdaysFormat } from '@/modules/settings/utils/parser/bhs'
import { ref } from 'vue'

const useBusinessHours = (mailbox:string[]) => {
  const storeMailboxes = useMailboxesStore()
  const enabled = ref(true)

  const getBusinessHoursMutation = useQuery(['businessHours', mailbox[0]], async ():Promise<void> => {
    const { data } = await clientsApi.get(`${BUSINESSHOURS}${mailbox[0]}`)
    storeMailboxes.setMailboxBusinessHours(mailbox[0], weekdaysFormat(data))
    return data
  }, { enabled })

  const createBusinessHoursMutation = useMutation(async (businessHours: IIBhs):Promise<void> => {
    businessHours.mailboxes?.forEach(async (mailbox:string) => {
      await clientsApi.post<IIBhs>(`${BUSINESSHOURS}${mailbox}`, {
        timezone: businessHours.timezone.replace(' ', '_'),
        business_hours: businessHours.business_hours
      })
      storeMailboxes.setMailboxBhsStatus(mailbox)
    })
  })

  const deleteBusinessHoursMutation = useMutation(async (mailboxes:string[]):Promise<void> => {
    mailboxes.forEach(async (mailbox:string) => {
      await clientsApi.delete(`${BUSINESSHOURS}${mailbox}`)
    })
  })

  return {
    // properties
    enabled,

    // Method
    getBusinessHours: getBusinessHoursMutation,
    createBusinessHours: createBusinessHoursMutation,
    deleteBusinessHours: deleteBusinessHoursMutation
  }
}

export default useBusinessHours
