<script setup>
import SelectSimple from '@/modules/shared/components/SelectSimple'
import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import useModal from '@/modules/shared/composable/useModal'
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import { ref } from 'vue'

const { hideModal } = useModal()
const { userInfo } = useUserInfo()

const selected = ref({ title: 'Feature request' })
const content = ref('')
const items = [
  { title: 'Feature request' },
  { title: 'Bug' },
  { title: 'Question' }
]

const handleInputTitle = () => {
  if (selected.value.title === items[0].title) return 'How can we improve Enterprise?'
  else if (selected.value.title === items[1].title) return 'What did you find?'
  return 'How can we help?'
}

const isDisabled = () => {
  if (content.value.length > 5) return false
  else return true
}

const sendFeedback = () => {
  const data = {
    email: userInfo.value.email,
    client: userInfo.value.clientName,
    role: userInfo.value.roles,
    type_of_feedback: selected.value.title,
    content: content.value
  }
  fetch('https://hooks.zapier.com/hooks/catch/10814139/21tw4pw/', {
    method: 'POST',
    body: JSON.stringify(data)
  })
  hideModal()
}
</script>

<template>
  <div>
      <div class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">Send feedback</div>
      <div class="p-6 overflow-auto text-sm leading-5 font-normal text-gray-700">Send feature requests, questions, bugs or any messages you would like to send to our team.</div>
      <div class="px-6">
          <SelectSimple :options="items" v-model:values="selected" label="Type of feedback" placeholder="Choose user role" />
      </div>
      <div class="px-6 pb-6 flex-col justify-start items-start gap-1 inline-flex mt-6 w-full border-b">
          <div class="flex-col justify-center items-start flex">
              <div class="justify-start items-center gap-1 inline-flex">
                  <div class="text-sm font-medium leading-5 text-gray-700">{{ handleInputTitle() }}</div>
              </div>
          </div>
          <textarea
              class="w-full self-stretch h-32 px-3 py-1.5 bg-white rounded-lg shadow-xs !border-none rounded-md appearance-none whitespace-normal box-border ring-1 ring-gray-300 focus-within:ring-2 resize-none justify-start items-center gap-2 inline-flex"
              @input="(val) => (content = val.target.value)"
          ></textarea>
      </div>
      <ButtonsModal :isDisabled="isDisabled()" @submit="sendFeedback()" />
  </div>
</template>
