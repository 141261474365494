<script setup lang="ts">
import { ref } from 'vue'
import googleUrl from '@/assets/icons/google.svg'
import microsoftUrl from '@/assets/icons/microsoft-icon.svg'
import logoUrl from '@/assets/images/logo.svg'
import { LOGIN_G, LOGIN_M } from '@/api/url'
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'

const hasError = ref<boolean>(Boolean(localStorage.getItem('errorLogin')))
const loginGoogle = ():void => {
  window.location.href = `${import.meta.env.VITE_BACKEND_HOST}${LOGIN_G}`
}

const loginMicrosoft = ():void => {
  window.location.href = `${import.meta.env.VITE_BACKEND_HOST}${LOGIN_M}`
}

const getInTouch = ():void => {
  window.open('https://www.emailmeter.com/enterprise-demo', '_blank')
}

const terms = ():void => {
  window.open('https://www.emailmeter.com/terms-of-service', '_blank')
}
</script>

<template>
  <div class="flex bg-white login-bg">
      <div class="w-full min-h-screen flex-col justify-center items-center inline-flex p-6">
          <div class="w-[162px] h-8 absolute left-8 top-8">
              <img class="w-[162px] h-6" :src="logoUrl" />
          </div>
          <div class="w-full min-h-52 flex-col justify-center items-center flex">
              <div class="w-full min-h-52 max-w-[448px] flex-col justify-start items-center gap-5 flex">
                  <div class="w-full flex-col justify-start items-center flex gap-1">
                      <div class="text-gray-950 text-xl sm:text-2xl font-semibold font-['Inter'] leading-loose">Log in to Email Meter Enterprise</div>
                      <div class="justify-center items-center gap-1 inline-flex gap-2">
                          <div class="text-gray-500 text-sm font-normal font-['Inter'] leading-normal">Don’t have an Enterprise account?</div>
                          <div class="justify-center items-center flex">
                            <button class="text-primary-400 inline-flex items-center justify-center font-inter font-semibold gap-1.5 antialiased transition ease-linear duration-75 enabled:cursor-pointer rounded-lg disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 whitespace-nowrap overflow-hidden text-ellipsis" @click="getInTouch">
                              <span class="text-primary-600 hover:text-primary-500 px-0 py-0 text-sm leading-5">Get in touch</span>
                            </button>
                          </div>
                      </div>
                  </div>
                  <div v-if="hasError" class="w-full px-3 py-4 bg-red-50 rounded-xl justify-start items-start gap-3 inline-flex">
                      <div class="pt-[1px] justify-start items-start flex">
                          <ExclamationCircleIcon class="text-red-700 w-4 h-4 relative" />
                      </div>
                      <div class="flex-col justify-start items-start gap-3 inline-flex">
                          <div class="self-stretch flex-col justify-center items-start gap-1 flex">
                              <div class="self-stretch text-red-700 text-sm font-medium font-['Inter'] leading-tight">Sorry, we couldn’t find your account</div>
                              <div class="self-stretch text-red-700 text-sm font-normal font-['Inter'] leading-tight">
                                If you believe this is an error, please get in touch with your administrator or contact support.
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="w-full flex-col justify-center items-center gap-2 flex">
                    <button class="h-[38px] text-sm leading-5 px-4 py-2 relative isolate bg-white inline-flex items-center justify-center gap-x-2 border border-gray-950/10 enabled:hover:bg-gray-950/[2.5%] text-gray-500 enabled:hover:text-gray-700 inline-flex items-center justify-center font-inter font-semibold gap-1.5 antialiased transition ease-linear duration-75 enabled:cursor-pointer rounded-lg disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 whitespace-nowrap overflow-hidden text-ellipsis w-full" @click="loginGoogle">
                      <img class="h-4 w-4" :src="googleUrl" />
                      <span class="text-sm/6 font-semibold text-gray-950">Continue with Google</span>
                    </button>
                    <button class="h-[38px] text-sm leading-5 h-9 px-4 py-2 relative isolate bg-white inline-flex items-center justify-center gap-x-2 border border-gray-950/10 enabled:hover:bg-gray-950/[2.5%] text-gray-500 enabled:hover:text-gray-700 inline-flex items-center justify-center font-inter font-semibold gap-1.5 antialiased transition ease-linear duration-75 enabled:cursor-pointer rounded-lg disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 whitespace-nowrap overflow-hidden text-ellipsis w-full" @click="loginMicrosoft">
                      <img class="h-4 w-4" :src="microsoftUrl" />
                      <span class="text-sm/6 font-semibold text-gray-950">Continue with Microsoft</span>
                    </button>
                  </div>
                  <div class="w-full flex-col sm:flex-row justify-center items-center inline-flex gap-1">
                      <div class="text-gray-500 text-sm font-normal font-['Inter'] leading-normal">By logging in you agree to the</div>
                      <div class="justify-center items-center flex">
                        <button class="text-gray-500 hover:text-gray-700 inline-flex items-center justify-center font-inter font-semibold gap-1.5 antialiased transition ease-linear duration-75 enabled:cursor-pointer rounded-lg disabled:opacity-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 whitespace-nowrap overflow-hidden text-ellipsis text-gray-950" @click="terms">
                          <span class="text-gray-950 hover:text-gray-600 px-0 py-0 text-sm leading-5">Terms and Conditions</span>
                        </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 908px) {
.login-bg {
  background-image: url(@/assets/images/background-down.svg), url(@/assets/images/background-up.svg);
  background-position: 0 100%, 100% 0;
  background-size: 20%, 25%;
  background-repeat: no-repeat, no-repeat;
}
}
</style>
