<script setup lang="ts">
import { computed } from 'vue'

import {
  bodyTeams,
  headTeams,
  bodySearchNotFound,
  BodyNotConnection,
  bodyNotData
} from '@/modules/settings/components/table'
import ButtonRS from '@/modules/shared/components/ButtonRemoveSelected.vue'

import useTable from '@/modules/settings/composables/useTable'
import useTeams from '@/modules/settings/composables/useTeams'
import MODALS from '@/modules/shared/utils/modals_enum'
import useModal from '@/modules/shared/composable/useModal'

const { search, selectedItems, clearSearch, filterData } = useTable()
const { teams: data, removeData, isErrorGet } = useTeams()
const { removeModal } = useModal()

defineProps({
  readOnly: {
    type: Boolean,
    default: false
  }
})

const teams = computed(() => filterData(data.value, ['team']))
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mt-5 flow-root">
      <div class="sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full pb-4 align-middle">
          <div class="relative">
            <div v-if="selectedItems.length > 0"
              class="absolute top-0.5 left-14 flex h-12 items-center space-x-3 sm:left-12">
              <ButtonRS text="Remove selected" @click="removeModal(removeData(selectedItems))" />
            </div>
            <div class="bg-white md:rounded-lg border border-gray-200">
              <table class="tabla min-w-full table-fixed divide-y divide-gray-200">
                <!-- Head -->
                <headTeams :selectFiltered="teams" :read-only="readOnly"/>

                <!-- Connection issues -->
                <BodyNotConnection
                  v-if="isErrorGet"
                  title="Something went wrong"
                  text="We had some troubles loading this data. Please refresh this page to try again and get in touch if the problem sticks around."
                  textButton="Refresh page" />

                <!-- Data -->
                <bodyTeams
                  v-else-if="data.length > 0 && teams.length > 0"
                  :teams="teams"
                  :read-only="readOnly"/>

                <!-- Search not found -->
                <bodySearchNotFound
                  v-else-if="data.length > 0 && teams.length === 0"
                  title="No teams found"
                  textButton="Add a new team"
                  :text='`Your search "${search}" didn’t match any teams. <br /> Please try again or create a new team.`'
                  :modal-name="MODALS.addTeams"
                  :read-only="readOnly"
                  @clearSearch="clearSearch"
                />

                <!-- No data saved -->
                <bodyNotData
                  v-else-if="data.length === 0" title="Start by creating a team"
                  text="Teams are a great way to group mailboxes for easier analysis. Once you have created Teams, you’ll be able to filter by them from your dashboard."
                  textButton="Create a new team"
                  :modal-name="MODALS.addTeams"
                  :read-only="readOnly"
                />
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
