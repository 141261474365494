import { storeToRefs } from 'pinia'
import { useBusinessHoursStore } from '@/modules/settings/stores/businessHours'
import { useMutation, useQuery } from '@tanstack/vue-query'
import type { IIBhs } from '@/modules/settings/interfaces/BusinessHours'
import clientsApi from '@/api/clients-api'
import { COMPANYBUSINESSHOURS } from '@/api/url'
import { weekdaysFormat } from '@/modules/settings/utils/parser/bhs'

const useCompanyBusinessHours = () => {
  const businessHoursStore = useBusinessHoursStore()
  const { businessHours } = storeToRefs(businessHoursStore)

  useQuery(['companyBusinessHours'], async ():Promise<IIBhs> => {
    const { data } = await clientsApi.get(COMPANYBUSINESSHOURS)
    businessHoursStore.setBusinessHours(weekdaysFormat(data))
    return data
  }, { staleTime: 10 * (60 * 1000) })

  const createCompanyBusinessHoursMutation = useMutation(async (businessHours: IIBhs):Promise<void> => {
    await clientsApi.post<IIBhs>(COMPANYBUSINESSHOURS, {
      business_hours: businessHours.business_hours,
      timezone: businessHours.timezone.replace(' ', '_')
    })
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteCompanyBusinessHoursMutation = useMutation(async (data: string):Promise<void> => {
    await clientsApi.delete(`${COMPANYBUSINESSHOURS}`)
  })

  return {
    // properties
    businessHours,

    // Method
    createCompanyBusinessHours: createCompanyBusinessHoursMutation,
    deleteCompanyBusinessHours: deleteCompanyBusinessHoursMutation
  }
}

export default useCompanyBusinessHours
