import exploreLayout from '@/modules/explore/pages/ExploreLayout.vue'

import AlertsExplore from '@/modules/explore/pages/AlertsExplore.vue'

import { ALERTS_ROUTES } from '@/modules/explore/utils/constants/routes'

const exploreRoutes = [
  {
    path: '/alerts/',
    name: ALERTS_ROUTES.Explore,
    component: exploreLayout,
    children: [
      {
        path: '',
        name: ALERTS_ROUTES.Alerts,
        component: AlertsExplore
      }
    ]
  }
]

export default exploreRoutes
