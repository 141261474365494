import { storeToRefs } from 'pinia'

import { useModalStore } from '@/modules/shared/stores/modal'
import { useTableStore } from '@/modules/settings/stores/table'
import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'
import type { IFShowModal, IFRemoveModal, IFEditUserData, IFEditTeamData, IFEditBusinessData, IFEditMailboxBusinessData, IFEditAlertData } from '@/modules/shared/interfaces/Modal'
import type { IMetricAdjustmentsFront } from '@/modules/settings/interfaces/MetricAdjustments'

import { ALERTS } from '@/api/url'
import clientsApi from '@/api/clients-api'

const useModal = () => {
  const storeModal = useModalStore()

  const {
    showModalRemove, showModalAdd, showModalUpdateUser, showModalUpdateTeam, showModalUpdateAlert, showModalMailboxBusinessHours, showModalUpdateBusiness, showModalUpdateMetricAdjustments,
    showModalUpdateInternalDomains, openModal
  } = useModalStore()
  const { data, modalData, deleteData, editUserData, editTeamData, editAlertData, editBusinessData, editMailboxBusinessData, activeModal, hasCloseButton } = storeToRefs(storeModal)
  const { setSelectedItems } = useTableStore()

  return {
    activeModal,
    data,
    modalData,
    deleteData,
    editUserData,
    editTeamData,
    editAlertData,
    editBusinessData,
    editMailboxBusinessData,
    openModal,
    removeModal: (data: IFRemoveModal) => {
      showModalRemove(data)
      setSelectedItems([])
    },
    showModal: (data: IFShowModal, useCloseButton = true) => {
      showModalAdd(data)
      hasCloseButton.value = useCloseButton
    },
    showModalUpdateUser: (data: IFEditUserData) => { showModalUpdateUser(data) },
    showModalUpdateTeam: (data: IFEditTeamData) => { showModalUpdateTeam(data) },
    showModalUpdateAlert: async (data: IFEditAlertData) => {
      const { data: d } = await clientsApi.get(`${ALERTS}${data.data.id}`)

      showModalUpdateAlert({ name: data.name, data: d })
    },
    showModalMailboxBusinessHours: (data: IFEditMailboxBusinessData) => {
      showModalMailboxBusinessHours(data)
    },
    showModalUpdateBusiness: (data: IFEditBusinessData) => {
      showModalUpdateBusiness(data)
    },
    showModalUpdateMetricAdjustments: (data: {name: string, data: IMetricAdjustmentsFront[]}) => {
      showModalUpdateMetricAdjustments(data)
    },
    showModalUpdateInternalDomains: (data: {name: string, data: Iitems[]}) => {
      showModalUpdateInternalDomains(data)
    },
    hideModal: () => storeModal.hideModal()
  }
}

export default useModal
