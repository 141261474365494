<script setup lang="ts">
import { ref } from 'vue'
import {
  Switch, SwitchDescription, SwitchGroup, SwitchLabel
} from '@headlessui/vue'
import { useToast } from 'vue-toastification'
import { useQueryClient } from '@tanstack/vue-query'

import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'
import { emailReg } from '@/modules/shared/utils/reg'
// import { numberToWords } from '@/modules/settings/utils/helpers/numbers'
import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import InputMultiple from '@/modules/shared/components/InputMultiple.vue'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import useModal from '@/modules/shared/composable/useModal'
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import ToastComponent from '@/modules/shared/components/ToastComponent.vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

const { userInfo } = useUserInfo()
const queryClient = useQueryClient()
const toast = useToast()

const { hideModal } = useModal()

const hasDataModal = false

const { onlyExistEmails, createMailboxes } = useMailboxes()

const enabled = ref(false)
const email = ref('')
const emails = ref<Iitems[]>([])

const isDisabled = () => !(!email.value.length && emails.value.length > 0 && !isDisabledText() && !(createMailboxes.isLoading.value || createMailboxes.isSuccess.value) && onlyItems().length + onlyExistEmails.value.length <= userInfo.value.maxMailboxes)
const isDisabledText = () => (emails.value.filter((e) => e.status === false).length > 0)
const onlyItems = () => emails.value.map((e) => e.item)

const ConfirmAddMail = () => {
  const now = new Date()
  if (enabled.value) {
    now.setFullYear(now.getFullYear() - 2)
  }

  if ((onlyItems().length + onlyExistEmails.value.length) <= userInfo.value.maxMailboxes) {
    const emailData = {
      emails: onlyItems(),
      data_from: new Date(now).toJSON().slice(0, 10)
    }
    createMailboxes.mutate(emailData, {
      onSuccess: () => {
        const query = queryClient.getQueryCache().find(['mailboxes'], { exact: true })
        query?.fetch()
        hideModal()
        const m = onlyItems().length
        toast.success({
          component: ToastComponent,
          props: {
            title: `Mailbox${m > 1 ? 'es' : ''} added`,
            messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
          },
          options: { icon: CheckCircleIcon }
        }, {
          timeout: 10000
        })
      }
    })
  }
}
</script>

<template>
    <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">Add new mailboxes</p>
    <div class="p-6 border-b overflow-auto">
      <p class="text-sm leading-5 font-normal text-gray-700 pb-8">Enter the list of mailboxes that you want to start tracking statistics for.</p>
      <div>
        <label for="email" class="pb-1 block text-sm font-medium leading-5 text-gray-700">Email address</label>

          <InputMultiple
            v-model:text="email"
            v-model:items="emails"

            :disabled="hasDataModal"
            :reg="(e:string) => (!(emailReg.test(e)))"
            :allowedDomains="userInfo.allowedDomains"
            :onlyExistEmails="onlyExistEmails"
          />
        <span v-show="isDisabledText()" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
          Please check that all email addresses are correct.
        </span>
        <span v-show="(onlyItems().length + onlyExistEmails.length) > (userInfo.maxMailboxes - 3)" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
          Your plan allows you to track up to {{ userInfo.maxMailboxes }} mailboxes. You can only add {{ (onlyItems().length + onlyExistEmails.length) > userInfo.maxMailboxes ? '0' : userInfo.maxMailboxes - (onlyItems().length + onlyExistEmails.length) }} more before reaching <br>
          that limit. Please remove some or <a class="underline" href="mailto:success@emailmeter.com">get in touch</a> to increase your limit.
        </span>
      </div>
      <div class="pt-8">
        <SwitchGroup as="div" class="flex items-center">
          <Switch v-model="enabled" :class="[enabled ? 'bg-indigo-600' : 'bg-gray-400', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0 focus:ring-offset-0']">
            <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
          </Switch>
          <span class="flex flex-grow flex-col ml-3.5">
            <SwitchLabel as="span" class="text-sm font-medium leading-5 text-gray-900" passive>Load two years of historical email data</SwitchLabel>
            <SwitchDescription as="span" class="text-sm leading-5 text-gray-500">If unselected, we’ll only start tracking email data from now on.</SwitchDescription>
          </span>
        </SwitchGroup>
      </div>
    </div>

    <ButtonsModal :isDisabled="isDisabled()" @submit="ConfirmAddMail" />
</template>

<style scoped></style>
