<script setup lang="ts">
import type { PropType } from 'vue'
import type { Ibhs } from '@/modules/shared/interfaces/Modal'
import type { IBusinessHours } from '@/modules/settings/interfaces/BusinessHours'

import selectSimple from '@/modules/shared/components/SelectSimple'
import useBusinessHours from './useBusinessHours'

interface Emits {
  (e: 'update:days', values: IBusinessHours[]): void;
  (e: 'update:timezone', values: string): void;
}

const emits = defineEmits<Emits>()

const props = defineProps({
  data: {
    type: Object as PropType<Ibhs>,
    required: true
  },
  days: {
    type: Array as PropType<IBusinessHours[]>,
    required: false
  },
  timezone: {
    type: String,
    required: false
  },
  readOnly: {
    type: Boolean,
    default: false
  }
})

const {
  DaysEmit, allHours, selected, allDays, timezonesOptions,
  updateModal, addDay, updateStart, updateEnd
} = useBusinessHours(props, emits)
</script>

<template>
  <selectSimple :readOnly="readOnly" :options="timezonesOptions" :values="selected" @update:values="updateModal" label="Timezone" placeholder="Select a timezone..." typeSelect="list" :finder="true" />

    <div class="mt-6">
      <p class="text-sm text-gray-700 font-medium">Schedule</p>
      <div id="schedule" class="mt-2 flex gap-3">
        <button
          v-for="day in allDays"
          :key="day.id"
          :class="[day.status ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-500', 'flex justify-center items-center rounded-3xl p-1 w-8', readOnly ? 'opacity-50 hover' : !day.status ? 'hover:bg-gray-300' : '']"
          :disabled="readOnly"
          @click="addDay(day)"
        >
          <span>{{ day.name }}</span>
        </button>
      </div>
    </div>
    <template v-for="(day, index) in DaysEmit" :key="day.id">
      <div v-if="day.status" class="scheduleDays mt-4 flex justify-between gap-2 items-center">
        <p class="basis-1/5 text-sm text-gray-700">{{ day.id }}</p>
        <div class="flex items-center gap-2 w-full">
          <selectSimple :readOnly="readOnly" :optionSimple="allHours[index].start_time" :valueSimple="DaysEmit[index].start_time" @update:valueSimple="(value: string) => updateStart(DaysEmit[index], value)"/>
          <selectSimple :readOnly="readOnly" :optionSimple="allHours[index].end_time" :valueSimple="DaysEmit[index].end_time" @update:valueSimple="(value: string) => updateEnd(DaysEmit[index], value)"/>
        </div>
      </div>
    </template>
</template>

<style>
</style>
