/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, type ExtractPropTypes, type PropType } from 'vue'
import type { IData } from './SelectSimple'

export interface PropsSelectMultiple {
  options: {
    type: PropType<IData[]>;
    required: false,
    default: () => [{ title: 'None', description: '' }]
  };
  values: {
    type: PropType<IData>;
    required: false,
    default: () => ({ title: 'None', description: '' })
  };
  optionSimple: {
    type: PropType<string[]>,
    required: false
  },
  valueSimple: {
    type: string,
    required: false
  },
  finder: {
    type: boolean,
    required: false,
    default: false
  },
}

interface Emits {
  (e: 'update:values', values: IData): void;
  (e: 'update:valueSimple', values: string): void;
}

const useSelectSimple = (props: Readonly<ExtractPropTypes<PropsSelectMultiple>>, emits:Emits) => {
  const open = ref(false)
  const search = ref('')

  const boxMiltiselect = ref()
  const multiselectContent = ref()
  const multiselectOptions = ref()
  const findId = ref()

  const calcWindow = () => {
    setTimeout(() => {
      const bottom = boxMiltiselect.value.getBoundingClientRect().bottom
      multiselectOptions.value.style.top = `${bottom + 5}px`
    }, 100)
  }

  const optionsFilter = () => {
    if (!search.value || !props.finder) return props.options
    return props.options.filter((v:IData) => (v.title.toLowerCase().includes(search.value?.toLowerCase())))
  }

  const optionSelected = (option:IData) => (Boolean(props.values.title === option.title))

  const selected = (option:IData) => { emits('update:values', option) }

  const selectedSimple = (option:string) => {
    emits('update:valueSimple', option)
  }

  const openSelect = () => {
    const bottom = boxMiltiselect.value.getBoundingClientRect().bottom
    const left = boxMiltiselect.value.getBoundingClientRect().left
    const width = boxMiltiselect.value.getBoundingClientRect().width
    const maxHeight = window.innerHeight - (bottom + 20)

    multiselectOptions.value.style.top = `${bottom + 5}px`
    multiselectOptions.value.style.left = `${left}px`
    multiselectOptions.value.style.width = `${width}px`

    if (maxHeight <= 200) multiselectContent.value.style.maxHeight = `${maxHeight}px`
    else multiselectContent.value.style.maxHeight = '200px'

    open.value = !open.value
    if (props.finder && open.value) {
      search.value = ''
      setTimeout(() => {
        findId.value.focus()
      }, 100)
    }
  }

  const closeDropdown = (e: { srcElement: any }): void => {
    const ids = []
    let element = e.srcElement

    while (element !== null) {
      ids.push(element.id)
      if (element.offsetParent) element = element.offsetParent
      else element = null
    }
    if (!ids.includes('finder')) open.value = false
  }

  return {
    open,
    search,
    multiselectContent,
    boxMiltiselect,
    multiselectOptions,
    findId,

    calcWindow,
    optionsFilter,
    optionSelected,
    selected,
    selectedSimple,
    openSelect,
    closeDropdown
  }
}

export default useSelectSimple
