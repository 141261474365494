import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { ITeams } from '@/modules/settings/interfaces/Teams'

export const useTeamsStore = defineStore('teams', () => {
  const teams = ref<ITeams[]>([])

  return {
    // properties
    teams,

    // Actions
    setTeams (newTeams: ITeams[]) { teams.value = newTeams }
  }
})
