import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IBhs } from '@/modules/settings/interfaces/BusinessHours'

export const useBusinessHoursStore = defineStore('businessHours', () => {
  const businessHours = ref<IBhs>({
    timezone: '',
    businessHours: []
  })

  return {
    // properties
    businessHours,

    // Actions
    setBusinessHours (newSettingsGeneral: IBhs) { businessHours.value = newSettingsGeneral }
  }
})
