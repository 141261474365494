import dayjs from 'dayjs'

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const setDates = (date:string):string => {
  const month = dayjs(date).format('MM')
  const dayYear = dayjs(date).format('DD, YYYY')

  return `${months[parseInt(month) - 1]} ${dayYear}`
}
