import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IUserInfo } from '@/modules/shared/interfaces/UserInfo'

export const useUserInfoStore = defineStore('userInfo', () => {
  const userInfo = ref<IUserInfo>({
    name: '',
    clientName: '',
    email: '',
    roles: '',
    isAdmin: '',
    emailProvider: '',
    features: {},
    allowedDomains: [],
    maxMailboxes: 0,
    maxUsers: 0,
    maxAlerts: 0,
    mixpanelId: ''
  })

  return {
    // properties
    userInfo,

    // method
    setUserInfo: (newUserInfo: IUserInfo) => {
      userInfo.value = newUserInfo
    }
  }
})
