<script setup lang="ts">
import { computed } from 'vue'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'
import useTable from '@/modules/settings/composables/useTable'
import useAlerts from '@/modules/explore/composables/useAlerts'

const props = defineProps({
  selectFiltered: {
    type: Object,
    required: true
  },
  readOnly: {
    type: Boolean,
    default: false
  }
})
const { asc, typeOrder, selectedItems, selectAll, indeterminate, orderBy } = useTable()
const { alerts } = useAlerts()
const all = computed(() => props.selectFiltered?.map((p: { id: string; }) => p?.id))
</script>

<template>
  <thead>
    <tr class="bg-gray-50">
      <th scope="col" class="relative px-7 sm:w-12 sm:px-6 rounded-t-md">
        <input
          v-if="!readOnly"
          id="check_first"
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500 focus:ring-0 focus:ring-offset-0"
          :checked="indeterminate(alerts.length) || selectedItems.length === alerts.length"
          :indeterminate="indeterminate(alerts.length)"
          @change="selectAll($event, all)" />
      </th>
      <th scope="col" class="w-3/6 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900" @click="orderBy('name')">
        <div class="flex items-center cursor-pointer group">
          Name
          <span class="ml-2 flex-none rounded" :class="[
            typeOrder === 'name' ?
              'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
              'invisible text-gray-400 group-hover:visible group-focus:visible'
          ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4" />
          </span>
        </div>
      </th>
      <th scope="col" class="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900" @click="orderBy('createdBy')">
        <div class="flex items-center relative w-max cursor-pointer group">
          Created by

          <span class="ml-2 flex-none rounded" :class="[
            typeOrder === 'createdBy' ?
              'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
              'invisible text-gray-400 group-hover:visible group-focus:visible'
          ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4" />
          </span>
        </div>
      </th>
      <th scope="col" class="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900" @click="orderBy('lastSent')">
        <div class="flex items-center relative w-max cursor-pointer group">
          Last triggered

          <span class="ml-2 flex-none rounded" :class="[
            typeOrder === 'lastSent' ?
              'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
              'invisible text-gray-400 group-hover:visible group-focus:visible'
          ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4" />
          </span>
        </div>
      </th>
      <th scope="col" class="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900" @click="orderBy('is_active')">
        <div class="flex items-center relative w-max cursor-pointer group">
          Status
          <span class="ml-2 flex-none rounded" :class="[
            typeOrder === 'active' ?
              'bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5' :
              'invisible text-gray-400 group-hover:visible group-focus:visible'
          ]">
            <ChevronDownIcon v-if="asc" class="h-4 w-4" aria-hidden="true" />
            <ChevronUpIcon v-else class="h-4 w-4" />
          </span>
        </div>
      </th>
      <th scope="col" class="w-1/6 relative py-3.5 pl-3 pr-4 sm:pr-3 rounded-r-md">
        <span class="sr-only">Edit</span>
      </th>
    </tr>
  </thead>
</template>

<style scoped></style>
