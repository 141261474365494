<script setup  lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  typeComponent: {
    type: String,
    default: 'button'
  },
  to: {
    type: String,
    default: ''
  },
  handlerClick: {
    type: Function,
    default: () => {}
  },
  icon: {
    type: [Object, Function],
    default: () => {}
  },
  text: {
    type: String,
    required: true
  },
  badge: {
    type: Object,
    default: () => {}
  }
})

const route = useRoute()
const color = computed(() => {
  const splited = props?.to?.split('/')
  if (splited[1] && route.path.includes(splited[1])) return 'primary'
  else return 'gray'
})
const setClassTypeItem = computed(() => typeItem[color.value])
const setClassIcon = computed(() => classIcon[color.value])
const setClassText = computed(() => classText[color.value])

const typeItem = Object.freeze({
  gray: 'bg-gray-100 hover:bg-gray-200',
  primary: 'bg-primary-500/10'
})

const classIcon = Object.freeze({
  gray: 'text-gray-500',
  primary: 'text-primary-700'
})

const classText = Object.freeze({
  gray: 'text-gray-500 hover:text-gray-700',
  primary: 'text-primary-600'
})
</script>

<template>
  <component
      :is="props.typeComponent"
      class="relative flex justify-start items-center px-3 py-1.5 gap-3 h-9 rounded-md cursor-pointer"
      :class="`${setClassTypeItem} ${setClassText}`"
      :to="props.to"
      @click="props.handlerClick"
  >
      <div class="flex items-center h-6">
          <component :is="icon" class="w-5 h-5 mr-3" :class="setClassIcon"></component>
          <span class="font-inter font-semibold text-sm/6">
              {{ props.text }}
          </span>
      </div>
      <div v-if="badge" class="flex justify-center items-center py-1">
          <component :is="badge"></component>
      </div>
  </component>
</template>
