import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { useAlertsStore } from '@/modules/explore/store/alerts'
import MODALS from '@/modules/shared/utils/modals_enum'
import { useMutation, useQuery } from '@tanstack/vue-query'
import type { IAlerts, ICreateAlerts, IDeleteAlerts } from '@/modules/explore/interfaces/Alerts'
import clientsApi from '@/api/clients-api'
import { ALERTS } from '@/api/url'

const useAlerts = () => {
  const alertsStore = useAlertsStore()
  const { alerts } = storeToRefs(alertsStore)

  const getUseAlerts = useQuery(['alerts'], async ():Promise<IAlerts[]> => {
    const { data } = await clientsApi.get(ALERTS)
    return data
  })
  watch(getUseAlerts.data, alerts => {
    if (alerts) alertsStore.setAlerts(alerts)
  }, { immediate: true })

  const createAlertsMutation = useMutation(async (alert: ICreateAlerts):Promise<void> => {
    await clientsApi.post<ICreateAlerts>(ALERTS, alert)
  })

  const editAlertsMutation = useMutation(async (data:{alert: ICreateAlerts | { is_active: boolean }, id:string}):Promise<void> => {
    await clientsApi.patch<ICreateAlerts>(`${ALERTS}${data.id}`, data.alert)
  })

  const deleteAlertsMutation = useMutation(async (alerts: IDeleteAlerts):Promise<void> => {
    await clientsApi.delete<IDeleteAlerts>(ALERTS, alerts)
  })

  return {
    // properties
    alerts,

    // Method
    createAlerts: createAlertsMutation,
    editAlerts: editAlertsMutation,
    deleteAlerts: deleteAlertsMutation,
    removeData: (ids:string[]) => {
      const maxLength = 50

      const ts = ids.map(t => {
        const name = alerts.value[alerts.value.findIndex((a) => a.id === t)].name
        return name.length > maxLength ? `"${name.slice(0, maxLength)}..."` : `"${name}"`
      })

      return {
        name: MODALS.removeModal,
        data: {
          type: 'alerts',
          title: `Are you sure you want to remove ${ids.length > 1 ? 'these alerts' : 'this alert'}?`,
          text: `Your alert ${ts.join(', ')} will be permanently deleted. You can also pause alerts if you plan on using them again.`,
          data: ids
        }
      }
    }
  }
}

export default useAlerts
