import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMutation, useQuery } from '@tanstack/vue-query'
import clientsApi from '@/api/clients-api'

import type { ITeams, IcreateTeams, IdeleteTeams } from '@/modules/settings/interfaces/Teams'
import { useTeamsStore } from '@/modules/settings/stores/teams'
import MODALS from '@/modules/shared/utils/modals_enum'
import { TEAMS } from '@/api/url'

const useTeams = () => {
  const store = useTeamsStore()
  const { teams } = storeToRefs(store)

  const getUseTeams = useQuery(['teams'], async ():Promise<ITeams[]> => {
    const { data } = await clientsApi.get(TEAMS)
    return data
  })
  watch(getUseTeams.data, teams => {
    if (teams) store.setTeams(teams)
  }, { immediate: true })

  const createTeamsMutation = useMutation(async (data: IcreateTeams):Promise<void> => {
    await clientsApi.post<ITeams>(TEAMS, data)
  })

  const deleteTeamsMutation = useMutation(async (data: IdeleteTeams):Promise<void> => {
    await clientsApi.delete<ITeams>(TEAMS, data)
  })

  return {
    teams,

    // Method
    createTeams: createTeamsMutation,
    deleteTeams: deleteTeamsMutation,

    removeData: (teams:string[]) => {
      const maxLength = 50
      const ts = teams.map(t => {
        return t.length > maxLength ? t.slice(0, maxLength) + '...' : t
      })

      return {
        name: MODALS.removeModal,
        data: {
          type: 'teams',
          title: `Are you sure you want to remove ${teams.length > 1 ? 'these teams' : 'this team'}?`,
          text: `The "${ts.join(', ')}" ${teams.length > 1 ? 'teams' : 'team'} will be permanently deleted, but the mailboxes inside it will still be tracked.`,
          data: teams
        }
      }
    },

    // computed
    // get mailboxes
    isErrorGet: computed(() => getUseTeams.isError.value)
  }
}

export default useTeams
