<!-- eslint-disable no-case-declarations -->

<script setup lang="ts">
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { useQueryClient } from '@tanstack/vue-query'
import { useToast } from 'vue-toastification'
import type { IModalData2, IModalDataMailboxes, IModalDataTeam } from '@/modules/shared/interfaces/Modal'

import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import useModal from '@/modules/shared/composable/useModal'
import useContactGroups from '@/modules/settings/composables/useContactGroups'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import { numberToWords } from '@/modules/settings/utils/helpers/numbers'
import useTeams from '@/modules/settings/composables/useTeams'

const queryClient = useQueryClient()
const toast = useToast()
const { modalData, hideModal } = useModal()
const { createTeams } = useTeams()

const { AddContactGroup, getContacts, renameContactGroup } = useContactGroups()
const { createMailboxes } = useMailboxes()

const ConfirmAddContact = () => {
  const data = (modalData.value as IModalData2)

  const is = data.items.map((d) => (d.item))

  is.filter((item, index) => is.indexOf(item) === index)

  AddContactGroup.mutate({ name: data.name!, mailboxes: is.filter((item, index) => is.indexOf(item) === index) }, {
    onSuccess: () => {
      getContacts.mutate(data.name, {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['contactGroups'], { exact: true })
          query?.fetch()
          hideModal()
          if (data.items.length === 1) toast.success('New contact added!')
          else toast.success('New contacts added!')
        }
      })
    }
  })
}

const ConfirmAddContactGroup = () => {
  const data = (modalData.value as IModalData2)

  const is = data.items.map((d) => d.item)

  AddContactGroup.mutate({ name: data.name, mailboxes: is.filter((item, index) => is.indexOf(item) === index) }, {
    onSuccess: () => {
      const query = queryClient.getQueryCache().find(['contactGroups'], { exact: true })
      query?.fetch()
      hideModal()
      toast.success('New contact group added!')
    }
  })
}

const ConfirmRenameContactGroup = () => {
  const data = (modalData.value as IModalData2)

  renameContactGroup.mutate({ name: data.name, newName: (data.newName as string) }, {
    onSuccess: () => {
      const query = queryClient.getQueryCache().find(['contactGroups'], { exact: true })
      query?.fetch()
      hideModal()
      toast.success('Contact group modified')
    }
  })
}

const ConfirmCreateMailboxes = () => {
  const data = (modalData.value as IModalDataMailboxes)

  createMailboxes.mutate(data.emailData, {
    onSuccess: () => {
      const query = queryClient.getQueryCache().find(['mailboxes'], { exact: true })
      query?.fetch()
      hideModal()
      const m = data.emailData.emails.length
      toast.success(`${numberToWords(m)} new mailbox${m > 1 ? 'es' : ''} added!`)
    }
  })
}

const ConfirmCreateTeams = () => {
  const data = (modalData.value as IModalDataTeam)

  createTeams.mutate(data.newTeamsData, {
    onSuccess: () => {
      const query = queryClient.getQueryCache().find(['teams'], { exact: true })
      query?.fetch()
      hideModal()
      data.hasDataModal
        ? toast.success('Team edited')
        : toast.success('Team created')
    }
  })
}

const Confirm = () => {
  switch ((modalData.value as IModalData2).type) {
    case 'AddContact':
      ConfirmAddContact()
      break
    case 'AddContactGroup':
      ConfirmAddContactGroup()
      break
    case 'RenameContactGroup':
      ConfirmRenameContactGroup()
      break
    case 'CreateMailboxes':
      ConfirmCreateMailboxes()
      break
    case 'createTeams':
      ConfirmCreateTeams()
      break
    default:
      break
  }
}
</script>

<template>
  <div id="modalSaveExcludeEmail" class="p-6 pb-0 overflow-auto">
    <div class="bg-indigo-100 w-12 h-12 flex items-center justify-center rounded-full ">
      <ExclamationCircleIcon class="w-6 h-6 text-indigo-500" />
    </div>
    <p class="text-gray-900 font-semibold leading-8 text-lg mt-5">{{ (modalData as IModalData2).msg.title }}</p>
    <p class="text-sm leading-5 font-normal text-gray-500 pt-2">{{ (modalData as IModalData2).msg.text }}</p>
  </div>

  <ButtonsModal @submit="Confirm"/>
</template>

<style scoped>

</style>
