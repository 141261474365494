import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMutation, useQuery } from '@tanstack/vue-query'

import clientsApi from '@/api/clients-api'
import SettingsParser from '@/modules/settings/utils/parser/settings'
import type { IUsers, IcreateUsers, IdeleteUsers } from '@/modules/settings/interfaces/Users'
import { useUsersStore } from '@/modules/settings/stores/users'
import { USERS } from '@/api/url'
import MODALS from '@/modules/shared/utils/modals_enum'

const useUsers = () => {
  const store = useUsersStore()
  const { users } = storeToRefs(store)

  const getUseUsers = useQuery(['users'], async ():Promise<IUsers[]> => {
    const { data } = await clientsApi.get(USERS)
    return SettingsParser.parseUsers(data)
  })
  watch(getUseUsers.data, users => {
    if (users) store.setUsers(users)
  }, { immediate: true })

  const usersCreateMutation = useMutation(async (data: IcreateUsers):Promise<void> => {
    await clientsApi.post<IUsers>(USERS, data)
  })

  const usersDeleteMutation = useMutation(async (data: IdeleteUsers):Promise<void> => {
    await clientsApi.delete<IUsers>(USERS, data)
  })

  return {
    users,
    // Method
    createUsers: usersCreateMutation,
    deleteUsers: usersDeleteMutation,
    removeData: (users:string[]) => ({
      name: MODALS.removeModal,
      data: {
        type: 'users',
        title: `Are you sure you want to remove ${users.length > 1 ? 'these users' : 'this user'}?`,
        text: `${users.join(', ')} will lose access to see email statistics.`,
        data: users
      }
    }),

    // computed
    isErrorGet: computed(() => getUseUsers.isError.value),
    onlyExistEmails: computed(() => [...users.value.map(m => m.email)])
  }
}

export default useUsers
