<script setup lang="ts">
import { ref, watch } from 'vue'
import { useQueryClient } from '@tanstack/vue-query'
import useUserInfo from '@/modules/shared/composable/useUserInfo'

import { UserGroupIcon, PlusIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/24/outline'
import { EllipsisHorizontalIcon } from '@heroicons/vue/20/solid'
import ButtonG from '@/modules/shared/components/ButtonComponent.vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import ButtonR from '@/modules/shared/components/ButtonRemove.vue'
import ButtonRS from '@/modules/shared/components/ButtonRemoveSelected.vue'
import useModal from '@/modules/shared/composable/useModal'
import useContactGroups from '@/modules/settings/composables/useContactGroups'

const queryClient = useQueryClient()
const { openModal } = useModal()
const { contactGroups, contacts, getContacts } = useContactGroups()
const { userInfo } = useUserInfo()

const currentGroup = ref(contactGroups.value.length ? contactGroups.value[0].contact_group : '')
const items = ref(contactGroups.value)

watch(contactGroups, () => {
  items.value = contactGroups.value
  const indexForDelete = contactGroups.value.findIndex((cg) => (cg.contact_group === currentGroup.value))
  if (contactGroups.value.length > 0 && indexForDelete === -1) {
    currentGroup.value = contactGroups.value[0].contact_group
    changeGroup(contactGroups.value[0].contact_group)
  }
})

const deleteItems = ref<string[]>([])

const changeGroup = (name:string) => {
  getContacts.mutate(name, {
    onSuccess: () => {
      const query = queryClient.getQueryCache().find(['contactGroups'], { exact: true })
      query?.fetch()
      currentGroup.value = name
      deleteItems.value = []
      orderItems.value = true
    }
  })
}

const selectAllItems = () => {
  if (deleteItems.value.length === 0) deleteItems.value = [...contacts.value.map(items => items.id)]
  else deleteItems.value = []
}
const selectItem = (mailbox:string) => {
  if (!deleteItems.value.includes(mailbox)) deleteItems.value = [...deleteItems.value, mailbox]
  else deleteItems.value = [...deleteItems.value.filter((m) => m !== mailbox)]
}

const itemsUpdate = () => (contacts.value.map((data) => ({ item: data.item, status: true })))

const orderGroup = ref<boolean>(true)
const orderItems = ref<boolean>(true)
const cGroup = ref<number>(1)
const cItems = ref<number>(1)

const handleOrderGroup = () => {
  orderGroup.value = !orderGroup.value

  items.value = items.value.sort(function (a, b) {
    if (a.contact_group > b.contact_group) {
      if (orderGroup.value) return 1
      else return -1
    }
    if (a.contact_group < b.contact_group) {
      if (orderGroup.value) return -1
      else return 1
    }
    return 0
  })

  cGroup.value++
}

const handleOrderItems = () => {
  if (cItems.value > 1) orderItems.value = !orderItems.value

  contacts.value.sort(function (a, b) {
    if (a.item > b.item) {
      if (orderItems.value) return 1
      else return -1
    }
    if (a.item < b.item) {
      if (orderItems.value) return -1
      else return 1
    }
    return 0
  })

  cItems.value++
}

const removeContacts = (items:string[] = []) => {
  deleteItems.value = []
  if (items.length === contacts.value.length) openModal({ name: 'removeContactGroup', hasCloseButton: false, data: { name: currentGroup.value, allContact: true } })
  else openModal({ name: 'removeContacts', hasCloseButton: false, data: { name: currentGroup.value, mailboxes: items } })
}

const disabled = userInfo.value.features?.contact_groups?.read_only
</script>

<template>
  <div class="pb-6">
    <div class="mt-6 mb-7">
      <p class="text-lg leading-6 font-medium text-gray-900">Contact Groups </p>
      <p class="text-sm leading-5 font-normal text-gray-500 mt-1 mb-3">
        Organize email addresses and domains in groups for easier reporting and filtering
      </p>
    </div>

    <div v-if="!items.length" class="flex flex-col border border-gray-200 rounded-lg overflow-hidden min-h-[624px]">
      <div class="bg-gray-50 text-gray-700 px-6 py-4 text-sm font-inter leading-5 font-semibold border-b border-gray-200">Contact Groups</div>
        <div class="bg-white flex flex-col grow justify-center">
        <div class="p-10 flex flex-col justify-center items-center">
          <div class="p-3 bg-indigo-100 rounded-full">
            <UserGroupIcon class="h-6 w-6 text-indigo-500 stroke-[1.5]" aria-hidden="true" />
          </div>
          <p class="text-gray-900 text-base leading-6 font-medium pt-2">Organize your contacts in groups</p>
          <p class="text-gray-500 text-sm leading-5 m-auto text-center pt-1">Create groups to organize email addresses and <br/> domains and quickly filter by them on your dashboard</p>
          <div class="w-1/3 pt-6 flex justify-center">
            <ButtonG
              @click="openModal({ name: 'addContactGroup', data: {} })"
              text="Create group"
              :inactive="disabled"
              class="ml-2 !w-36"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else class="flex border border-gray-200 rounded-lg bg-white min-h-[624px]">
      <div class="flex flex-col w-[283px] border-r border-gray-200">
        <div class="flex items-center bg-gray-50 text-gray-700 px-6 py-4 text-sm font-inter leading-5 font-semibold border-b border-gray-200 rounded-t-lg">
          Contact Groups
          <span class="ml-2 flex-none rounded bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5">
            <ChevronDownIcon v-if="orderGroup" @click="handleOrderGroup" class="h-4 w-4 cursor-pointer" aria-hidden="true" />
            <ChevronUpIcon v-else @click="handleOrderGroup" class="h-4 w-4 cursor-pointer" aria-hidden="true" />
          </span>
        </div>
        <div class="flex flex-col justify-between grow">
          <div class="flex flex-col max-h-[517px] grow overflow-auto">
            <div
              v-for="item in items"
              :key="item.contact_group"
              class="groups px-6 py-2 flex justify-between items-center cursor-pointer "
              :class="[{'bg-gray-100': item.contact_group === currentGroup}]"
            >
            <div class="flex flex-col grow" @click="changeGroup(item.contact_group)">
                <span class="text-gray-700 font-medium leading-5 text-sm">{{ item.contact_group }}</span>
                <span class="text-gray-500 text-sm font-normal leading-5">{{ item.contacts_number }} {{ item.contacts_number === 1 ? 'contact' : 'contacts' }} </span>
              </div>

              <Menu v-if="!disabled" as="div" class="menu-group relative inline-block text-left">
                <MenuButton class="flex items-center rounded text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 focus:ring-offset-0">
                  <span class="sr-only">Open options</span>
                  <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
                </MenuButton>

                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="absolute py-1 right-0 z-10 w-[164px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div>
                        <MenuItem >
                          <ButtonR type-color="gray" text="Rename" @click="openModal({ name: 'renameContactGroup', data: { name: item.contact_group } })" />
                        </MenuItem>
                        <MenuItem >
                          <ButtonR type-color="red" text="Remove group" @click="openModal({ name: 'removeContactGroup', hasCloseButton:false, data: { name: item.contact_group } })" />
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </transition>
                </Menu>
            </div>
          </div>

          <div v-if="!disabled" @click="openModal({ name: 'addContactGroup', data: { } })" class="px-6 pt-2 pb-[14px] flex gap-[6px] items-center font-semibold text-sm text-gray-500 cursor-pointer">
            <PlusIcon class="h-4 w-4 text-gray-500" aria-hidden="true" />
            <span class="text-sm leading-5 font-semibold text-gray-500 hover:text-gray-700">New group</span>
          </div>
        </div>
      </div>

      <div class="flex flex-col flex-1">
        <div class="flex justify-between gap-4 items-center bg-gray-50 text-gray-700 px-6 py-[11px] text-sm font-inter leading-5 font-semibold border-b border-gray-200 rounded-t-lg">
          <div class="flex items-center">
            <input
              v-if="!disabled"
              :id="deleteItems.length !== contacts.length ? 'check_first' : ''"
              type="checkbox"
              class="h-4 w-4 mr-4 rounded border-gray-300 text-indigo-500 focus:ring-0 focus:ring-offset-0"
              @click="selectAllItems()"
              :checked="deleteItems.length > 0"
              />
            <div class="flex items-center">
              Contacts
              <span class="ml-2 flex-none rounded bg-gray-100 text-gray-900 hover:bg-gray-200 p-0.5">
                <ChevronDownIcon v-if="orderItems" @click="handleOrderItems" class="inline h-4 w-4 cursor-pointer" aria-hidden="true" />
                <ChevronUpIcon v-else @click="handleOrderItems" class="inline h-4 w-4 cursor-pointer" aria-hidden="true" />
              </span>
              <div v-if="deleteItems.length > 0" class="flex items-center space-x-3 sm:left-12">
                <ButtonRS text="Remove contacts" class="ml-4" @click="removeContacts(deleteItems)" />
              </div>
            </div>
          </div>
          <ButtonG
            v-if="!disabled"
            @click="openModal({ name: 'addContact', data: { name: currentGroup, mailboxes: itemsUpdate() } })"
            text="Add contacts"
            class="!px-[11px] !py-[7px] !w-[105px] text-xs"
          />
        </div>
        <div class="flex flex-col grow h-[562px] overflow-auto">
          <div v-for="data in contacts" :key="data.id" class="px-6 py-[10px] flex justify-between text-gray-900 font-normal leading-5 text-sm border-b border-gray-200">
            <div class="flex gap-6 items-center">
              <input
                v-if="!disabled"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-500 focus:ring-0 focus:ring-offset-0"
                @click="selectItem(data.id)"
                :checked="deleteItems.includes(data.id)"
              />
              {{ data.item }}
            </div>

            <Menu v-if="!disabled" as="div" class="menu-contact relative inline-block text-left">
              <MenuButton class="flex items-center rounded text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 focus:ring-offset-0">
                <span class="sr-only">Open options</span>
                <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
              </MenuButton>

              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute py-1 right-0 z-10 w-[164px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div>
                    <MenuItem >
                      <ButtonR type-color="red" text="Remove contact" @click="removeContacts([data.id])" />
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
