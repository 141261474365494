<script lang="ts" setup>
import { computed } from 'vue'
import { useToast } from 'vue-toastification'

import headAlerts from '@/modules/explore/components/table/headAlerts.vue'
import bodyAlerts from '@/modules/explore/components/table/bodyAlerts.vue'
import useTable from '@/modules/settings/composables/useTable'
import useAlerts from '@/modules/explore/composables/useAlerts'
import ButtonRS from '@/modules/shared/components/ButtonRemoveSelected.vue'
import ButtonG from '@/modules/shared/components/ButtonComponent.vue'
import {
  bodySearchNotFound,
  bodyNotData
} from '@/modules/settings/components/table'
import useModal from '@/modules/shared/composable/useModal'
import MODALS from '@/modules/shared/utils/modals_enum'
import useUserInfo from '@/modules/shared/composable/useUserInfo'

const { userInfo } = useUserInfo()
const toast = useToast()

const { alerts: data, removeData } = useAlerts()
const { search, selectedItems, clearSearch, filterData } = useTable()
const { showModal, removeModal } = useModal()
const alerts = computed(() => filterData(data.value, ['name']))

defineProps({
  readOnly: {
    type: Boolean,
    default: false
  }
})

const createAlert = () => {
  if (userInfo.value.maxAlerts > data.value.length) {
    showModal({ name: 'addAlerts' })
  } else {
    toast.error('You\'ve reched your limit of alerts')
  }
}
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="mt-5 flow-root">
      <div class="sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full pb-4 align-middle">
          <div class="relative">
            <div v-if="selectedItems.length > 0"
              class="absolute top-0.5 left-14 flex h-12 items-center space-x-3 sm:left-12">
              <ButtonRS text="Delete alerts" @click="removeModal(removeData(selectedItems))" />
            </div>
            <div class="bg-white md:rounded-lg border border-gray-200">

              <table class="tabla min-w-full table-fixed divide-y divide-gray-200">
                <!-- Head -->
                <headAlerts :selectFiltered="alerts" :read-only="readOnly" />

                <bodyAlerts
                  v-if="data.length > 0 && alerts.length > 0"
                  :alerts="alerts"
                  :read-only="readOnly"
                />

                <!-- Search not found -->
                <bodySearchNotFound
                  v-else-if="data.length > 0 && alerts.length === 0"
                  title="No alerts found"
                  :text='`Your search "${search}" didn’t match any alert. <br /> Please try again or add a new alert.`'
                  textButton="Create a new alert"
                  :modal-name="MODALS.addAlerts"
                  @clearSearch="clearSearch"
                >
                <template v-slot:button>
                  <ButtonG
                    @click="createAlert"
                    text="Create a new alert"
                    :inactive="readOnly"
                    class="ml-2 !w-48"
                  />
                </template>

                </bodySearchNotFound>

                <!-- No data saved -->
                <bodyNotData
                  v-else-if="data.length === 0"
                  title="Never miss an important email again"
                  text="Alerts are a great way to make sure critical emails are always responded on time. For example, you can get alerted when an important customer has not been replied to within 2 hours."
                  textButton="Create my first alert"
                  :modal-name="MODALS.addAlerts"
                  :read-only="readOnly">
                  <template v-slot:icon>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M15 17H20L18.5951 15.5951C18.2141 15.2141 18 14.6973 18 14.1585V11C18 8.38757 16.3304 6.16509 14 5.34142V5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5V5.34142C7.66962 6.16509 6 8.38757 6 11V14.1585C6 14.6973 5.78595 15.2141 5.40493 15.5951L4 17H9M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17M15 17H9" stroke="#2E72F3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </template>
                </bodyNotData>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
