<script setup lang="ts">
import { watch, ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import { SETTINGS_ROUTES } from '@/modules/settings/utils/constants/routes'
import useUserInfo from '@/modules/shared/composable/useUserInfo'

const route = useRoute()
const router = useRouter()
const routes = ['MailboxesSettings', 'UsersSettings', 'TeamsSettings', 'BusinessSettings', 'MetricAdjustments', 'ContactGroupsSettings']
const userInfo = useUserInfo()
const tabs = ref([
  { name: 'Mailboxes', href: SETTINGS_ROUTES.MailboxesSettings, current: false },
  { name: 'Users', href: SETTINGS_ROUTES.UsersSettings, current: false },
  { name: 'Teams', href: SETTINGS_ROUTES.TeamsSettings, current: false },
  { name: 'Business Hours', href: SETTINGS_ROUTES.BusinessSettings, current: false },
  { name: 'Metric Adjustments', href: SETTINGS_ROUTES.MetricAdjustments, current: false },
  { name: 'Contact Groups', href: SETTINGS_ROUTES.ContactGroupsSettings, current: false }
])

onBeforeMount(() => {
  Object.entries(userInfo.userInfo.value?.features).forEach(([key, value]) => {
    let index:number | undefined
    if (key && value && !value.enabled) {
      tabs.value.forEach((tab, i) => {
        if (tab.name.toLowerCase().substring(0, 6).includes(key.substring(0, 6))) index = i
      })
      if (typeof index === 'number') {
        tabs.value.splice(index, 1)
        routes.splice(index, 1)
      }
    }
  })
})

watch(route, (newVal) => {
  routes.forEach((settingRoute: string, index:number) => {
    if (newVal.name === SETTINGS_ROUTES[settingRoute as 'MailboxesSettings' | 'UsersSettings' | 'TeamsSettings' | 'MetricAdjustments' | 'BusinessSettings'| 'ContactGroupsSettings']) tabs.value[index].current = true
    else tabs.value[index].current = false
  })
}, { immediate: true })

watch(userInfo.userInfo, (newVal) => {
  if (newVal.roles !== 'admin') router.push({ name: 'accessDenied' })
})

</script>

<template>
    <div class="max-w-[1120px] w-full h-full">
      <p class="text-gray-800 text-3xl font-bold leading-9">Settings</p>
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <router-link v-for="tab in tabs" :to="{ name: tab.href}" :key="tab.name" :class="[tab.current ? 'border-indigo-500 text-indigo-500' : 'border-transparent transition duration-0 hover:duration-200 text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']">{{ tab.name }}</router-link>
        </nav>
      </div>
      <RouterView />
    </div>
</template>
