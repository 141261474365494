import type { IBusinessHours } from '@/modules/settings/interfaces/BusinessHours'

export const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export const orderDays = (Days:IBusinessHours[]) => {
  return Days.sort((a, b) => {
    const i = weekdays.indexOf(a.id)
    const ii = weekdays.indexOf(b.id)

    if (i > ii) return 1
    if (i < ii) return -1
    return 0
  })
}
