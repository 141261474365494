import { computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import clientsApi from '@/api/clients-api'
import { DASHBOARD } from '@/api/url'
import type { IDashboard } from '@/modules/dashboard/interfaces/dashboard'
import { useDashboardStore } from '@/modules/dashboard/stores/dashboard'

const getDashboard = async ():Promise<IDashboard[]> => {
  const { data } = await clientsApi.get(DASHBOARD)
  if (!data.count) return data
  else return data
}

const useDashboard = () => {
  const storeDasboard = useDashboardStore()
  const { dashboard } = storeToRefs(storeDasboard)

  const getUseDashboard = useQuery(['dashboard'], getDashboard)
  watch(getUseDashboard.data, dashboardNew => {
    if (dashboardNew) storeDasboard.setDashboard(dashboardNew)
  }, { immediate: true })

  const href = (e:{ preventDefault: ()=> void }) => {
    if (dashboard.value.length) {
      window.dataLayer = window.dataLayer || []
      window.open(dashboard.value[0].dashboard_url, '_blank')
      window.dataLayer.push({
        origin: 'enterprise_portal',
        event: 'openInLookerButton'
      })
    } else e.preventDefault()
  }

  return {
    // properties
    dashboard,

    // Method
    href,
    // computed
    getData: computed(() => getUseDashboard.data),
    isLoadingGet: computed(() => getUseDashboard.isLoading.value),
    isErrorGet: computed(() => getUseDashboard.isError.value)
  }
}

export default useDashboard
