import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IUsers } from '@/modules/settings/interfaces/Users'

export const useUsersStore = defineStore('users', () => {
  const users = ref<IUsers[]>([])

  return {
    // properties
    users,

    // Actions
    setUsers (newUsers: IUsers[]) { users.value = newUsers }
  }
})
