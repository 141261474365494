// cuidado no cambiar
import { orderDays, weekdays } from '@/modules/settings/utils/helpers/days'

import type { IIBhs, IBhs, IIBusinessHours } from '@/modules/settings/interfaces/BusinessHours'

export const weekdaysFormat = (data:IIBhs): IBhs => {
  const businessHours = data.business_hours.map((bhs:IIBusinessHours) => {
    const day = (weekdays.find((d) => d.includes(bhs.weekday)) as string)
    return {
      id: day,
      name: day[0],
      status: true,
      start_time: bhs.start_time.slice(0, 5),
      end_time: bhs.end_time.slice(0, 5)
    }
  })
  return {
    timezone: data.timezone != null ? data.timezone.replace('_', ' ') : data.timezone,
    businessHours: orderDays(businessHours)
  }
}
