import { ref, type ExtractPropTypes, type PropType, computed, watch } from 'vue'

// import timezones from 'timezones-list'

import type { Ibhs } from '@/modules/shared/interfaces/Modal'
import type { IData } from '@/modules/shared/components/SelectSimple/SelectSimple'
import type { IBusinessHours } from '@/modules/settings/interfaces/BusinessHours'
import { weekdays, orderDays } from '@/modules/settings/utils/helpers/days'

interface Emits {
  (e: 'update:days', values: IBusinessHours[]): void;
  (e: 'update:timezone', values: string): void;
}

const schedule = () => {
  return weekdays.map((a) => {
    return { id: a, name: a[0], status: false }
  })
}

interface PropsBusinessHours {
  data: {
    type: PropType<Ibhs>,
    required: true
  }
}

const useBusinessHours = (props: Readonly<ExtractPropTypes<PropsBusinessHours>>, emits:Emits) => {
  const allDays = ref(schedule())

  const timezonesOptions = computed(() => {
    return Intl.supportedValuesOf('timeZone').sort((a, b) => {
      if (a > b) return 1
      if (a < b) return -1
      return 0
    }).map((t) => ({ title: t.replaceAll('_', ' '), description: '' }))
  })

  const selected = ref<IData>((() => {
    const selectedValue = (timezonesOptions.value.find((t) => (t.title === props.data?.timezone)) as IData)
    if (selectedValue) return selectedValue
    return { title: '', description: '' }
  })())

  const updateModal = (newValue:IData) => {
    selected.value = newValue
    emits('update:timezone', selected.value.title)
  }

  //
  const hoursF = computed(() => {
    const init = 0
    const end = 23
    const hours = []
    for (let i:number = init; i <= end; i++) {
      if (i < 10) {
        hours.push(`0${i}:00`)
        hours.push(`0${i}:30`)
      } else if (i >= 10) {
        hours.push(`${i}:00`)
        hours.push(`${i}:30`)
      }
    }
    return hours
  })

  const calcHours = (value:string) => {
    const indexStart = hoursF.value.findIndex((h) => h === value)
    return hoursF.value.filter((h, index) => index >= indexStart)
  }

  const DaysEmit = ref<IBusinessHours[]>((() => {
    if (props.data?.businessHours) return props.data.businessHours
    return []
  })())

  const allHours = ref<{ start_time: string[]; end_time: string[]; }[]>((() => {
    if (props.data?.businessHours) {
      return DaysEmit.value.map((day) => {
        allDays.value.forEach((d) => {
          if (d.id === day.id) d.status = true
        })
        return { start_time: calcHours('00:00'), end_time: calcHours('09:00') }
      })
    }
    return []
  })())

  watch(props, (newData) => {
    if (newData.data.timezone === 'UTC') selected.value = { title: '', description: '' }

    const selectedValue = (timezonesOptions.value.find((t) => (t.title === newData.data.timezone)) as IData)
    if (selectedValue) selected.value = selectedValue

    DaysEmit.value = newData.data.businessHours
    allHours.value = DaysEmit.value.map((day) => {
      allDays.value.forEach((d) => { if (d.id === day.id) d.status = true })

      return { start_time: calcHours('00:00'), end_time: calcHours(day.start_time) }
    })
  })

  const addDay = (day: { id: string, name: string, status: boolean }) => {
    day.status = !day.status

    const hasDay = DaysEmit.value.some(({ id }) => id === day.id)

    if (!hasDay) {
      DaysEmit.value = orderDays([...DaysEmit.value, { ...day, start_time: '09:00', end_time: '17:30' }])
      allHours.value = [...allHours.value, { start_time: calcHours('00:00'), end_time: calcHours('17:30') }]
    } else DaysEmit.value = DaysEmit.value.filter(({ id }) => id !== day.id)

    emits('update:days', DaysEmit.value)
  }

  const updateStart = (day:IBusinessHours, value:string) => {
    const indexStart = hoursF.value.findIndex((h) => h === value)
    const indexEnd = hoursF.value.findIndex((h) => h === day.end_time)

    if (indexStart >= indexEnd) day.end_time = hoursF.value[indexStart + 1]
    day.start_time = value
  }

  const updateEnd = (day:IBusinessHours, value:string) => { day.end_time = value }
  return {
    DaysEmit,
    allHours,
    selected,
    allDays,
    timezonesOptions,
    // properties

    // Method
    updateModal,
    addDay,
    updateStart,
    updateEnd
  }
}

export default useBusinessHours
