<script setup lang="ts">
import type { PropType } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid'

import useSelectMultiple from './useSelectMultiple'
interface Emits {
  (e: 'update:values', values2: string[]): void;
}
const emits = defineEmits<Emits>()

const props = defineProps({
  options: {
    type: Array as PropType<string[]>,
    required: true
  },
  values: {
    type: Array as PropType<string[]>,
    required: true
  },
  placeholder: {
    type: String,
    required: false
  },
  label: {
    type: String,
    required: false
  }
})

const {
  open,
  search,
  multiselectContent,
  boxMiltiselect,
  multiselectOptions,
  optionsFilter,
  optionSelected,
  selected,
  selectedAll,
  selectedNone,
  DeleteItem,
  openSelect,
  closeDropdown
} = useSelectMultiple(props, emits)
</script>

<template>
  <div v-if="label" class="text-sm font-medium leading-5 text-gray-700 mb-1">{{ label }}</div>
  <div
    ref="boxMiltiselect"
    v-click-outside="closeDropdown"
    class="flex justify-between w-full text-sm py-0 relative bg-white shadow-xs !border-none rounded-md appearance-none whitespace-normal box-border ring-1 ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-500"
  >
    <div id="inputEmail" class="flex items-center basis-full py-2 pl-[13px] pr-3 grow flex-wrap gap-y-1 gap-x-2 min-h-[38px] max-h-20 overflow-y-auto overflow-hidden" @click.self="openSelect">
      <span v-if="values.length === 0 && placeholder" class="text-gray-500" @click="openSelect">{{ placeholder }}</span>
      <span
        v-else
        v-for="value in values"
        :key="value"
        class="flex items-center border bg-white border-gray-300 pr-1 pt-[1px] pb-[1px] pl-2.5 font-medium text-xs leading-[18px] text-center bg-transparent text-gray-800 rounded"
      >
        {{ value }}
        <div class="ml-1 rounded-full hover:bg-gray-200 w-4 h-4 group flex items-center justify-center cursor-pointer">
          <XMarkIcon class="w-3 h-3 text-gray-400 group-hover:text-gray-500 inline-block" @click="DeleteItem(value)" />
        </div>
      </span>
    </div>
    <div @click="openSelect" class="pr-3 py-2 absolute right-0">
      <ChevronUpIcon v-show="open" class="w-5 h-5 text-gray-500 group-hover:text-gray-500 inline-block" />
      <ChevronDownIcon v-show="!open" class="w-5 h-5 text-gray-500 group-hover:text-gray-500 inline-block" />
    </div>
  </div>

  <Teleport to="body">
    <div id="SelectMultipleOptions" v-show="open" ref="multiselectOptions" class="shadow-select absolute block border z-50 rounded-md border-t-[none] border-solid border-gray-300 top-0 overflow-auto bg-white">
      <div class="relative mt-2 rounded-md shadow-sm min-h-[38px] mx-3 my-2">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input v-model="search" id="searchSelectMultiple" type="email" class="block w-full min-h-[38px] rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Search" />
      </div>

      <div class="flex justify-between text-gray-400 font-semibold text-xs leading-4 px-3 py-2">
        <span>MAILBOXES</span>
        <div>
          <span class="pr-2 cursor-pointer text-gray-400 hover:text-indigo-500" @click="selectedAll">ALL</span>
          <span class="cursor-pointer text-gray-400 hover:text-indigo-500" @click="selectedNone">NONE</span>
        </div>
      </div>

      <ul class=" inline-block min-w-full align-top min-h-[30px] max-h-[200px] overflow-auto m-0 p-0 list-none" ref="multiselectContent">
        <li
          v-for="(mailbox, index) in optionsFilter()"
          :key="index"
          @click="selected(mailbox)"
          class="block"
        >
        <span
          class="multiselect__optionEM text-left font-normal cursor-pointer text-sm leading-5 min-h-[auto] block no-underline normal-case relative whitespace-nowrap p-2 text-gray-900"
          :class="{'multiselect__option--selectedEM': optionSelected(mailbox)}"
        >
          <span>{{ mailbox }}</span>
        </span>
        </li>
      </ul>
    </div>
  </Teleport>
</template>

<style>
/*  */
.multiselect__optionEM::before {
  content: "";
  width: 17px;
  height: 16px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 4px;
  padding: 0;
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
}
.multiselect__optionEM::after {
    content: "" !important;
    background: none !important;
    padding: 0px;
}
/*  */
.multiselect__option--selectedEM::before {
    background: #2E72F3;
    border: 1px solid #2E72F3;
}

.multiselect__option--selectedEM:after {
    right: auto;
    content: "";
    position: absolute;
    left: 14px;
    top: 11px;
    width: 5px !important;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: block;
}
</style>
