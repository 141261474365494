import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { ContactGroups, Contacts } from '@/modules/settings/interfaces/ContactGroups'

export const useContactGroupsStore = defineStore('contactGroups', () => {
  const contactGroups = ref<ContactGroups[]>([])
  const contacts = ref<Contacts[]>([])

  return {
    // properties
    contactGroups,
    contacts,
    // Actions
    setContactGroups (contactGroupsValue: ContactGroups[]) {
      contactGroups.value = contactGroupsValue
    },
    setContact (newContacts: Contacts[]) {
      contacts.value = newContacts
    }
  }
})
