export default {
  addMailboxes: 'addMailboxes',
  deteleMailboxes: 'deteleMailboxes',
  mailboxLimitReached: 'mailboxLimitReached',
  updateBusiness: 'updateBusiness',

  addUser: 'addUser',
  deteleUser: 'deteleUser',
  updateUser: 'updateUser',
  userLimitReached: 'userLimitReached',

  addTeams: 'addTeams',
  deteleTeams: 'deteleTeams',
  updateTeams: 'updateTeams',

  addAlerts: 'addAlerts',
  deteleAlerts: 'deteleAlerts',
  updateAlerts: 'updateAlerts',

  removeModal: 'removeModal',
  addBusiness: 'addBusiness',

  updateMetricAdjustments: 'updateMetricAdjustments',
  updateInternalDomains: 'updateInternalDomains',

  addContactGroup: 'addContactGroup',
  addContact: 'addContact',
  removeContacts: 'removeContacts',
  renameContactGroup: 'renameContactGroup',
  removeContactGroup: 'removeContactGroup',

  confirmSaveModal: 'confirmSaveModal',
  sendFeedback: 'sendFeedback'
}
