<script setup lang="ts">
import Avatar from '@/modules/shared/components/AvatarComponent.vue'
import ArrowRightRectIcon from './Icons/ArrowRightRectIcon.vue'
import { useMainStore } from '@/modules/shared/stores/mainStore'
import clientsApi from '@/api/clients-api'
import { useRouter } from 'vue-router'

const router = useRouter()
const mainStore = useMainStore()

const props = defineProps({
  hintText: {
    type: String,
    default: ''
  },
  supportingText: {
    type: String,
    default: ''
  }
})

const logout = async (): Promise<void> => {
  await clientsApi.post('/portal/expire-token/')
  localStorage.removeItem('userToken')
  router.push({ name: 'login' })
}
</script>

<template>
    <section class="w-full p-3 border-t">
        <div class="flex items-center justify-between rounded-md px-3 hover:bg-gray-200 focus:bg-gray-100 cursor-pointer" @click="logout">
            <div class="flex items-center">
              <Avatar type="initials" :avatar-name="props.hintText" />
                <div class="w-full flex flex-col font-inter px-3 py-5 w-full">
                    <span class="font-semibold text-xs text-gray-700 w-[140px] truncate ..." :class="mainStore.isMobileOrTablet ? 'w-[200px]' : 'w-[140px]'">{{ props.hintText }}</span>
                    <span class="font-medium text-xs text-gray-500 w-[140px] truncate ...">{{ props.supportingText }}</span>
                </div>
            </div>
            <div class="w-5 h-5">
                <ArrowRightRectIcon class="w-5 h-5 text-gray-500" />
            </div>
        </div>
    </section>
</template>
