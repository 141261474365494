import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IDashboard } from '@/modules/dashboard/interfaces/dashboard'

export const useDashboardStore = defineStore('dashboard', () => {
  const dashboard = ref<IDashboard[]>([{
    name: '',
    created: '',
    dashboard_url: '',
    dashboard_embedded_url: ''
  }])

  return {
    // properties
    dashboard,

    // Actions
    setDashboard (newDashboard: IDashboard[]) {
      dashboard.value = newDashboard
    }
  }
})
