import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useMutation, useQuery } from '@tanstack/vue-query'
import clientsApi from '@/api/clients-api'

import type { IMailboxes, IcreateMailboxes, IdeleteMailboxes } from '@/modules/settings/interfaces/Mailboxes'
import { MAILBOXES } from '@/api/url'
import { useMailboxesStore } from '@/modules/settings/stores/mailboxes'
import SettingsParser from '@/modules/settings/utils/parser/settings'
import MODALS from '@/modules/shared/utils/modals_enum'

const useMailboxes = () => {
  const storeMailboxes = useMailboxesStore()
  const { mailboxes } = storeToRefs(storeMailboxes)

  const getUseMailboxes = useQuery(['mailboxes'], async ():Promise<IMailboxes[]> => {
    const { data } = await clientsApi.get(MAILBOXES)
    const mailboxes = SettingsParser.parseMailboxes(data)
    storeMailboxes.setMailboxes(mailboxes)
    return mailboxes
  })

  const createMailboxesMutation = useMutation(async (mailboxes: IcreateMailboxes):Promise<void> => {
    try {
      await clientsApi.post<IMailboxes>(MAILBOXES, mailboxes)
    } catch (error:unknown) {
      console.error(error)
    }
  })

  const deleteMailboxesMutation = useMutation(async (data: IdeleteMailboxes):Promise<void> => {
    await clientsApi.delete<IMailboxes>(MAILBOXES, data)
  })

  return {
    // properties
    mailboxes,

    // Method
    createMailboxes: createMailboxesMutation,
    deleteMailboxes: deleteMailboxesMutation,
    removeData: (mailboxes:string[]) => ({
      name: MODALS.removeModal,
      data: {
        type: 'mailboxes',
        title: `Are you sure you want to remove ${mailboxes.length > 1 ? 'these mailboxes' : 'this mailbox'}?`,
        text: `We’ll stop collecting email statistics for ${mailboxes.join(', ')}.`,
        data: mailboxes
      }
    }),
    // computed
    isErrorGet: computed(() => getUseMailboxes.isError.value),
    onlyExistEmails: computed(() => [...mailboxes.value.map(m => m.email)])
  }
}

export default useMailboxes
