import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useTableStore = defineStore('table', () => {
  const asc = ref<boolean>(false)
  const selectedItems = ref<string[]>([])
  const search = ref<string>('')
  const typeOrder = ref<string>('email')

  return {
    // properties
    asc,
    search,
    selectedItems,
    typeOrder,

    // Actions
    setAsc (newAsc:boolean) { asc.value = newAsc },
    setSearch (newSearch:string) { search.value = newSearch },
    setSelectedItems (newSettingsGeneral:string[]) {
      selectedItems.value = newSettingsGeneral
    },
    setTypeOrder (newTypeOrder:string) { typeOrder.value = newTypeOrder }
  }
})
