const DEBUG_LOG = import.meta.env.VITE_LOG

function generateError (error) {
  if (typeof error === 'string') {
    return new Error(error)
  } else {
    return error
  }
}

export default class Log {
  static info (...args) {
    if (DEBUG_LOG) console.info(...args)
  }

  static warn (...args) {
    if (DEBUG_LOG) console.warn(...args)
  }

  static error (msg, extra) {
    if (DEBUG_LOG) console.error(msg, extra)

    if (import.meta.env.VITE_BUGSNAG_KEY) {
      window.bugsnagClient.notify(generateError(msg), {
        metaData: extra
      })
    }
  }
}
