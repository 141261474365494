<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query'
import { ref } from 'vue'
import { useToast } from 'vue-toastification'

import useMailboxes from '@/modules/settings/composables/useMailboxes'
import useUsers from '@/modules/settings/composables/useUsers'
import type { IRoles } from '@/modules/settings/interfaces/Users'

import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import InputMultiple from '@/modules/shared/components/InputMultiple.vue'
import SelectMultiple from '@/modules/shared/components/SelectMultiple'
import selectSimple from '@/modules/shared/components/SelectSimple'
import useModal from '@/modules/shared/composable/useModal'
import useUserInfo from '@/modules/shared/composable/useUserInfo'

import { numberToWords } from '@/modules/settings/utils/helpers/numbers'
import MODALS from '@/modules/shared/utils/modals_enum'
import { emailReg } from '@/modules/shared/utils/reg'

const toast = useToast()
const { userInfo } = useUserInfo()
const { onlyExistEmails, createUsers } = useUsers()

const { editUserData, hideModal, activeModal } = useModal()
const email = ref('')
const emails = ref<{ item: string; status: true; notTraking: boolean; }[]>([])
const { mailboxes } = useMailboxes()
const queryClient = useQueryClient()

const hasDataModal = activeModal.value === MODALS.updateUser

if (hasDataModal) {
  emails.value = [
    {
      item: editUserData.value.email,
      status: true,
      notTraking: !(mailboxes.value.filter((p) => (p.email === editUserData.value.email)).length > 0)
    }
  ]
}

const userRoleTitle: IRoles[] = [
  {
    title: 'Admin',
    description: 'Can see email statistics for all tracked mailboxes.'
  },
  {
    title: 'Manager',
    description: 'Can see email statistics only for the mailboxes you give permission to.'
  },
  {
    title: 'Viewer',
    description: 'Can only see their own email statistics.'
  }
]

const findRoles = (role: string): IRoles => userRoleTitle[userRoleTitle.findIndex((i) => i.title === role)]

const role = ref(
  hasDataModal
    ? findRoles(editUserData.value.role)
    : {
        title: '',
        description: ''
      }
)

const value = ref(
  hasDataModal
    ? editUserData.value.mailboxes.map((m: string) => (m))
    : []
)
const options = ref(mailboxes.value.map((m) => (m.email)))

const isDisabled = () => {
  if ((role.value as IRoles).title === 'Manager' && value.value.length === 0) return true
  if ((onlyItems().length + onlyExistEmails.value.length) > userInfo.value.maxUsers) return true
  if (email.value.length === 0 && emails.value.length > 0 && !isDisabledText() && !(createUsers.isLoading.value || createUsers.isSuccess.value) && role.value.title) return false
  return true
}

const isDisabledText = () => emails.value.filter((e: { status: boolean }) => e.status === false).length > 0
const onlyItems = () => emails.value.map((e: { item: string }) => e.item)

const ConfirmAddMail = () => {
  if ((onlyItems().length + onlyExistEmails.value.length) <= userInfo.value.maxUsers) {
    const newUserData = {
      emails: onlyItems(),
      role: (role.value as IRoles).title.toLowerCase(),
      manage_mailboxes: value.value.length > 0 ? value.value : []
    }

    if (newUserData.role === 'viewer' && emails.value.some((p: { notTraking: boolean }) => p.notTraking)) {
      hideModal()
      toast.error(`${emails.value.length > 1 ? 'These users doesn’t' : 'This user don’t'} have a tracked ${emails.value.length > 1 ? 'mailboxes' : 'mailbox'}`)
    } else {
      createUsers.mutate(newUserData, {
        onSuccess: () => {
          const query = queryClient.getQueryCache().find(['users'], { exact: true })
          query?.fetch()
          hideModal()
          if (hasDataModal) {
            toast.success('Role updated')
          } else {
            const m = newUserData.emails.length
            toast.success(`${numberToWords(m)} new user${m > 1 ? 's' : ''} added!`)
          }
        }
      })
    }
  }
}

</script>

<template>
  <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">{{ hasDataModal ? 'Edit role' : 'Add new users'
  }}</p>
  <div class="p-6 border-b overflow-auto">
    <p v-if="!hasDataModal" class="text-sm leading-5 font-normal text-gray-700 pb-8">Enter the email addresses of the
      users you’d like to add and choose their roles.</p>
    <div class="pb-6">
      <label for="email" class="block text-sm font-medium leading-5 text-gray-700 pb-1">Email address</label>

      <InputMultiple
        v-model:text="email"
        v-model:items="emails"
        :disabled="hasDataModal"
        :reg="(e: string) => (!(emailReg.test(e)))" :allowedDomains="[]" :onlyExistEmails="[]"
      />
      <span v-show="isDisabledText()" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
        Please check that all email addresses are correct.
      </span>
      <span v-show="(onlyItems().length + onlyExistEmails.length) > (userInfo.maxUsers - 3)" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
        Your plan allows you to add up to {{ userInfo.maxUsers }} users. You can only add {{ (onlyItems().length + onlyExistEmails.length) > userInfo.maxUsers ? '0' : userInfo.maxUsers - (onlyItems().length + onlyExistEmails.length) }}
        more before reaching that <br> limit. Please remove some or <a class="underline" href="mailto:success@emailmeter.com">get in touch</a> to increase your limit.
      </span>
    </div>
    <div>
      <selectSimple :options="userRoleTitle" v-model:values="role" label="User role" placeholder="Choose user role" />
    </div>
    <div v-if="(role as IRoles)?.title === 'Manager'" class="pt-6">
      <SelectMultiple v-model:values="value" :options="options" placeholder="Select mailboxes"
        label="Managed mailboxes" />
    </div>
  </div>
  <ButtonsModal :isDisabled="isDisabled()" @submit="ConfirmAddMail" />
</template>
