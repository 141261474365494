import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import bulmaVariables from '../constants/bulmaVariables'

export const useMainStore = defineStore('main', () => {
  const windowWidth = ref<number>(0)
  const isMobile = computed(() => windowWidth.value <= bulmaVariables.MOBILE)
  const isMobileOrTablet = computed(() => windowWidth.value <= bulmaVariables.TABLET)

  return {
    // properties
    windowWidth,
    isMobile,
    isMobileOrTablet,

    // Actions
    setWindowWidth (width: number) {
      windowWidth.value = width
    }
  }
})
