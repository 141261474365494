// import { watch } from 'vue'
// import { useQuery } from '@tanstack/vue-query'
import clientsApi from '@/api/clients-api'
import { storeToRefs } from 'pinia'
import parseUserInfo from '@/modules/shared/utils/parser/userInfo'
// import type { IUserInfo } from '@/modules/shared/interfaces/UserInfo'
import { useUserInfoStore } from '@/modules/shared/stores/userInfo'
import { ME } from '@/api/url'

const useUserInfo = () => {
  const store = useUserInfoStore()
  const { userInfo } = storeToRefs(store)

  // const { isLoading, data } = useQuery(
  //   ['userInfo'],
  //   getUserInfo,
  //   { staleTime: 10 * (60 * 1000) }
  // )
  const getUserInfo = async () => {
    const { data } = await clientsApi.get(ME)
    store.setUserInfo(parseUserInfo(data))
    return parseUserInfo(data)
  }

  // watch(data, newUserInfo => {
  //   if (newUserInfo) store.setUserInfo(newUserInfo)
  // }, { immediate: true })

  return {
    userInfo,
    getUserInfo
    // isLoading
  }
}

export default useUserInfo
